import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageBannerImgBackground from '../components/PageBannerImgBackground/pageBannerImgBackground'


export const IndividualBlogTemplate = ({
    pageName,
    bannerImg,
    bannerTitle,
    content
}) => {



    const getImagePath = (image) => {
        if (image?.publicURL) return image.publicURL
        else return image
      }

      
  return (
    <div className='blog-wrapper'>

        <PageBannerImgBackground
        imgPath={getImagePath(bannerImg)}
        title={bannerTitle}
        />
        <div className='body-content-wrapper'>
            <div style={{color: ' rgb(114, 114, 114)'}} dangerouslySetInnerHTML={{ __html: content }} />     
        </div>
    </div>
  )
}

IndividualBlogTemplate.propTypes = {
    pageName: PropTypes.string,
    bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    bannerTitle: PropTypes.string,
    content: PropTypes.string,

  }

  const IndividualBlogPage = ({ data }) => {
    const { markdownRemark: post } = data
    const { frontmatter } = data.markdownRemark

      return (
        <Layout
        title={frontmatter.bannerTitle}
        >
              <IndividualBlogTemplate
                pageName={frontmatter.pageName}
                bannerImg={frontmatter.bannerImg}
                bannerTitle={frontmatter.bannerTitle}
                content={post.html}

              />

        </Layout>
      )
}


IndividualBlogPage.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    }),
}

export default IndividualBlogPage


export const pageQuery = graphql`
  query TrendsPostID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        pageName
        bannerImg {
            extension
            publicURL
          }
        bannerTitle
      }
    }
  }
`
